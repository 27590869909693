<template>
  <div class="section">
    <div class="d-flex justify-content-center mb-1 mt-3" v-if="isLoading">
      <b-spinner label="Loading..." />
    </div>
    <vue-good-table
      v-else
      max-height="80vh"
      :columns="columns"
      :rows="stocks"
      :fixed-header="false"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>
      <template slot="table-row" slot-scope="props">
        <span
          v-if="props.column.field === 'delivery_numbers'"
          id="delivery_numbers"
        >
          <div v-if="props.row.delivery_numbers">
            <span v-if="props.row.delivery_numbers.length < 3">{{
              props.row.delivery_numbers
                .map((e) => e.delivery_number_note)
                .slice(0, 3)
                .join(", ")
            }}</span>
            <b-dropdown
              v-else
              :text="
                props.row.delivery_numbers
                  .map((e) => e.delivery_number_note)
                  .slice(0, 3)
                  .join(', ')
              "
              variant="flat-secondary"
              class="px-0"
            >
              <b-row class="mx-0 pt-1">
                <b-col
                  v-for="(item, index) in props.row.delivery_numbers"
                  :key="index"
                  class=""
                >
                  <h6 class="text-dark">{{ item.delivery_number_note }}</h6>
                </b-col>
              </b-row>
            </b-dropdown>
          </div>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown id="dropdown-dropleft" class="d-flex" right>
              <template #button-content class="btn-white text-center">
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item
                @click="
                  $store.commit('stockWarehouse/setIsWarehouse', false),
                    $router.push({
                      name: 'stock.gudang.detail',
                      params: { id: props.row.uuid },
                    }),
                    $store.commit('stockWarehouse/setIsDeliveryNumbers', true)
                "
              >
                <span> Ubah </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkPermission('batalkan produk masuk supplier')"
                @click="deleteData(props.row.uuid)"
              >
                <span> Hapus </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10', '15', '20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.total > 0 ? filter.per_page * (rows.current_page - 1) + 1 : 0
          }}
          ke
          {{
            rows.total > 0
              ? Math.min(filter.per_page * rows.current_page, rows.total)
              : 0
          }}
          dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- <ModalEditIncomeProduct /> -->
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormSelect,
  VBToggle,
  BRow,
  BCol,
  BSpinner,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BRow,
    BCol,
    BSpinner,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    result: {
      type: Object,
    },
    getData: {
      type: Function,
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      columns: [
        {
          label: "Tanggal",
          field: "date",
        },
        {
          label: "Nomor PO",
          field: "po_so_id",
        },
        {
          label: "Pemasok",
          field: "supplier.name",
        },
        {
          label: "No. Surat Jalan",
          field: "delivery_numbers",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: {},
      stocks: [],
      filter: {
        per_page: 10,
      },
      isLoading: true,
    };
  },
  watch: {
    loading(value) {
      this.isLoading = value;
    },
    result: {
      handler(value) {
        this.rows = value;
        this.stocks = value.data;
      },
      deep: true,
    },
  },
  mounted() {
    // this.rows = this.result
    // this.stocks = this.result.data
  },
  methods: {
    async deleteData(uuid) {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin untuk menghapus stok gudang ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "supplier/deleteData",
              `/stocks/supplier/incoming/${uuid}`
            )
            .then(() => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Stok pemasok berhasil dihapus",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {});
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "../../../assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-responsive {
  height: 71.5vh;
  background-color: #fff;
}

#delivery_numbers .dropdown-menu {
  width: 25rem;
  height: 12rem;
  overflow: scroll;
}
#delivery_numbers .dropdown-toggle::after {
  left: -105%;
}
#delivery_numbers .dropdown-toggle {
  text-align: center;
  padding: 0.5rem 1.5rem;

  &:hover,
  &:focus {
    background-color: transparent !important;
  }
}
</style>
