<template>
  <div class="section">
    <header-page>
      <router-link :to="{ name: 'stock.index' }">
        <span
          class="size14 fw-bold-700"
          style="color: #0b5fff; margin-right: 5px"
          >Pengaturan Stok</span
        >
      </router-link>
      <span class="text-dark text-darken-5" style="margin-right: 5px">></span>
      <span class="text-dark fw-bold-700 size14">Stok Pemasok</span>
    </header-page>
    <div class="transaction__filter--container py-2 pl-0 pr-2">
      <b-button-group>
        <b-button
          v-for="(item, index) in listFilter"
          :key="`${item}-${index}`"
          :id="`button--filter${index}`"
          class="filter--button"
          :class="{ active: item == filter }"
          @click="
            $store.commit('stockWarehouse/setIsWarehouse', false),
              (filter = item),
              (comp =
                item == 'Produk Masuk'
                  ? 'TableIncomeProduct'
                  : 'TableStockActual')
          "
        >
          {{ item }}
        </b-button>
      </b-button-group>
      <div class="d-flex search__export">
        <FilterButton
          v-if="filter == 'Produk Masuk'"
          :is-actual="filter !== 'Produk Masuk' ? true : false"
          :is_transaction="true"
          :is_stock="true"
          @filterBase="getFilterBase"
        />

        <b-button
          id="button--produk__masuk"
          v-if="
            checkPermission('add stok supplier') && comp == 'TableIncomeProduct'
          "
          variant="outline-secondary py-1 bg-secondary text-white"
          @click="goToAddIncomingProduct"
        >
          <span>Tambah Produk Masuk</span>
        </b-button>
      </div>
    </div>
    <div
      class="d-flex search-export justify-content-between p-1 bg-white"
      v-if="comp == 'TableIncomeProduct'"
    >
      <div class="search__input w-100 mr-1">
        <b-input-group>
          <b-form-input
            v-model="filters.supplier_name"
            placeholder="Cari Pemasok"
          />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="search__input w-100">
        <b-input-group>
          <b-form-input
            v-model="filters.delivery_number"
            placeholder="Cari No Surat Jalan"
          />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <keep-alive>
      <TableIncomeProduct
        v-if="filter === 'Produk Masuk'"
        :result="result"
        :get-data="getStockSupplier"
        :loading="isLoading"
      />
      <TableStockActual
        v-if="filter === 'Stok Aktual'"
        :get-stock-actual="getStockActual"
        :result-actual="resultActual"
        :is-supplier="isSupplier"
        @filterStockActual="getFilterStockActual"
        :loading="isLoading"
      />
      <!-- <component :is="comp" /> -->
    </keep-alive>
    <ModalIncomeProduct
      :get-stock-supplier="getStockSupplier"
      :text-header="'di Pemasok'"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BButton,
  BButtonGroup,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import HeaderPage from "@/components/HeaderPage.vue";
import TableIncomeProduct from "@/components/Stock/Supplier/TableIncomeProduct.vue";
import TableStockActual from "@/components/Stock/Gudang/TableStockActual.vue";
import ModalIncomeProduct from "@/components/Stock/Modal/ModalIncomeProduct.vue";
import FilterButton from "@/components/BaseFilter.vue";

export default {
  title() {
    return "Stok Pemasok";
  },
  components: {
    BButton,
    HeaderPage,
    BButtonGroup,
    FilterButton,
    TableIncomeProduct,
    TableStockActual,
    ModalIncomeProduct,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      listFilter: ["Produk Masuk", "Stok Aktual"],
      filter: "Produk Masuk",
      comp: "TableIncomeProduct",
      result: {},
      resultActual: {},
      filters: {},
      isSupplier: true,
      isLoading: false,
    };
  },
  watch: {
    "$store.state.stockWarehouse.payloadRetail": {
      handler(value) {
        this.formPayload = value;
      },
      deep: true,
    },
    filters: {
      handler: _.debounce(function () {
        if (this.filter == "Produk Masuk") {
          this.getStockSupplier();
        }
        if (this.filter == "Stok Aktual") {
          this.getStockActual();
        }
      }, 300),
      deep: true,
    },
  },
  created() {
    const userPermission = localStorage.getItem("userPermission");

    if (userPermission && !userPermission.includes("list stok supplier")) {
      this.listFilter = ["Stok Aktual"];
      this.filter = "Stok Aktual";
      this.comp = "TableStockActual";
      this.getStockActual();
    } else if (
      userPermission &&
      !userPermission.includes("list aktual stok supplier")
    ) {
      this.listFilter = ["Produk Masuk"];
      this.filter = "Produk Masuk";
      this.comp = "TableIncomeProduct";
      this.getStockSupplier();
    } else {
      this.getStockSupplier();
      this.getStockActual();
    }
  },
  methods: {
    getFilterStockActual(value) {
      this.filters = {
        ...this.filters,
        per_page: value.per_page,
      };
    },
    goToAddIncomingProduct() {
      this.$store.commit("stockWarehouse/setFormSupplier", {});
      this.$store.commit("stockWarehouse/setIsWarehouse", false);
      this.$store.commit("stockWarehouse/setIsDeliveryNumbers", true);
      this.$router.push({ name: "stock.add.index", query: { warehouse: "0" } });
    },
    getFilterBase(value) {
      this.filters = value;
    },
    async getStockSupplier(page = 1) {
      this.isLoading = true;
      const queryParams = this.filters;
      queryParams.page = page;
      await this.$store
        .dispatch("stockWarehouse/getStockSupplier", {
          uuid: "",
          params: queryParams,
        })
        .then((result) => {
          this.result = result.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
            this.isLoading = false;
          }
          // eslint-disable-next-line no-console
          console.log(err);
          this.isLoading = false;
        });
    },
    async getStockActual(page = 1) {
      this.isLoading = true;
      const queryParams = this.filters;
      queryParams.page = page;
      await this.$store
        .dispatch("stockWarehouse/getStockActualSupplier", {
          uuid: "",
          params: queryParams,
        })
        .then((result) => {
          this.resultActual = result.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.transaction {
  position: relative;

  .vgt-table.bordered {
    thead {
      th:last-child {
        text-align: center;
      }
    }
    td {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      vertical-align: middle;
    }
    th {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      color: #6b6c7e;
      font-weight: 700;
    }
  }

  .page-item {
    .page-link {
      font-weight: 600;
      color: $secondary-2 !important;
      background-color: transparent;
    }
    &.active {
      .page-link {
        border-radius: 0;
        background-color: $light--1;
        font-weight: 600;
        color: $secondary-2 !important;
      }
    }
  }

  .page-item.prev-item ~ .page-item:nth-child(2) .page-link {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .page-item.prev-item ~ .page-item:nth-last-child(2) .page-link {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .page-item.next-item .page-link:active,
  .page-item.next-item .page-link:hover {
    background-color: transparent !important;
  }

  .pagination__container {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border: 1px solid #e4e5ec;

    .dropdown {
      button {
        font-size: 14px;
        color: $dark;
        font-weight: 600;

        &:hover {
          background-color: transparent;
        }
      }
    }

    small {
      color: $secondary-2;
      font-size: 14px;
      font-weight: 400;
    }
  }

  #dropdown-dropleft {
    .dropdown-toggle {
      border-color: transparent !important;
      background-color: transparent !important;
      color: $dark !important;

      &:hover {
        border-color: transparent !important;
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
  .transaction__filter--container {
    display: flex;
    justify-content: space-between;

    .search__export {
      .search__input {
        margin-right: 8px;
        .input-group {
          width: 23.451vw;
          height: 48px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

          input {
            height: 48px;
            border: none;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          .input-group-append {
            .input-group-text {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border: none;
            }
          }
        }
      }
      #search {
        width: 278px;
        height: 48px;
        border: none;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
      }

      .btn.bg-white {
        border: none !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-radius: 8px;

        img {
          margin-right: 8px;
        }

        span {
          color: $dark;
          font-size: 14px;
          font-weight: 800;
        }
      }
    }

    .filter--button {
      border: none !important;
      color: $light--2 !important;
      background-color: transparent !important;
      border-color: transparent !important;

      &.active {
        color: $secondary !important;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: -48%;
          left: 0;
          width: 50%;
          margin-left: 25%;
          border-radius: 4px 4px 0px 0px;
          border: 2px solid $secondary;
        }
      }

      &:hover {
        box-shadow: none !important;
      }
    }
  }
}
</style>
